import React from 'react';
import PropTypes from 'prop-types';

import InternalGoogleMap from 'google-map-react';
import { GoogleMapOfficeMarker } from '/components/google-map/components';

const GoogleMap = ({ address, latitude, longitude }) => (
    <InternalGoogleMap
        bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
        defaultCenter={{
            lat: latitude,
            lng: longitude,
        }}
        defaultZoom={14}
    >
        <GoogleMapOfficeMarker
            address={address}
            lat={latitude}
            lng={longitude}
        />
    </InternalGoogleMap>
);

GoogleMap.propTypes = {
    address: PropTypes.string.isRequired,
    latitude: PropTypes.number.isRequired,
    longitude: PropTypes.number.isRequired,
};

export { GoogleMap };
