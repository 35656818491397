import React from 'react';

import { EstateNavigationService } from '/services';

const EstateNavigation = () => {
    const overviewUrl = EstateNavigationService.getSearchUrl();
    const estates = EstateNavigationService.getSearchResults();

    const activeEstate = estates.findIndex(
        xxx => xxx === window.location.toString(),
    );

    if (activeEstate === -1) {
        return null;
    }

    const previousEstate = estates[activeEstate - 1];
    const nextEstate = estates[activeEstate + 1];

    if (Boolean(previousEstate || nextEstate) === false) {
        return null;
    }

    return (
        <div className="estate-navigation-wrapper">
            <div className="container is-horizontal">
                <div className="estate-navigation font-supplementary--semibold">
                    {previousEstate && (
                        <a
                            className="estate-navigation__link is-left"
                            href={previousEstate}
                        >
                            <svg viewBox="0 0 5 15">
                                <use xlinkHref="#icon-arrow-right" />
                            </svg>
                            <span>Vorige</span>
                        </a>
                    )}
                    {overviewUrl && (
                        <a
                            className="estate-navigation__link is-center"
                            href={overviewUrl}
                        >
                            <span className="estate-navigation-overview-icon" />
                            <span>Overzicht</span>
                        </a>
                    )}
                    {nextEstate && (
                        <a
                            className="estate-navigation__link is-right"
                            href={nextEstate}
                        >
                            <span>Volgende</span>
                            <svg viewBox="0 0 5 15">
                                <use xlinkHref="#icon-arrow-right" />
                            </svg>
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export { EstateNavigation };
