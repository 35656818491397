import PhotoSwipe from 'photoswipe';
import PhotoSwipeDefaultLayout from 'photoswipe/dist/photoswipe-ui-default';

import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

(() => {
    const pswp = document.querySelector('.pswp');
    const slides = document.querySelectorAll('[data-lightbox-image]');

    if (slides.length && pswp) {
        const images = [];

        slides.forEach(slide => {
            const { src, width, height } = slide.dataset;

            images.push({
                src,
                w: +width,
                h: +height,
                element: slide,
            });
        });

        slides.forEach((slide, index) => {
            slide.addEventListener('click', () => {
                const photoSwipe = new PhotoSwipe(
                    pswp,
                    PhotoSwipeDefaultLayout,
                    images,
                    {
                        index,
                        getThumbBoundsFn: (index) => {
                            const thumbnail = images[index].element;
                            const bcr = thumbnail.getBoundingClientRect();

                            return {
                                x: bcr.left,
                                y: bcr.top + window.pageYOffset,
                                w: bcr.width,
                                h: bcr.height,
                            };
                        }
                    },
                );

                photoSwipe.init();
            });
        });
    }
})();
