import React from "react";

export const Dropdown = props => {
  return (
    <svg width="25px" height="25px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="dropdown-sm" transform="translate(-95.000000, -11.000000)">
          <g id="dropdown-driehoek" transform="translate(95.000000, 11.000000)">
            <polygon id="Triangle" fill="#000000" fillRule="evenodd" transform="translate(20.500000, 15.000000) scale(1, -1) translate(-20.500000, -15.000000) " points="20.5 10 28 20 13 20" />
          </g>
        </g>
      </g>
    </svg>
  );  
};
