const createArrayFromZeroToX = x => {
    return Array.from(Array(x).keys()).map( x => { return { id:`${x}`, title: `${x}`, count: 1 } } );
};

const filters = {
    prune: (categories, priceClasses, cities, allEstates) => {
        if (allEstates.length !== 0) {
            var bedrooms = createArrayFromZeroToX(Math.max(...allEstates.map( estate => isNaN(parseInt(estate.bedrooms)) ? 0 : parseInt(estate.bedrooms) )) + 1);
        } else {
            var bedrooms = [0];
        }

        return {
            priceClasses,
            allEstates,
            categories,
            cities,
            bedrooms
        };
    }
};

export const pruneData = (categories, priceClasses, cities, allEstates) => {
    return filters.prune(categories, priceClasses, cities, allEstates);
};
