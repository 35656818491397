import * as actionTypes from "./actionTypes";
import { getData } from "../utils/ajaxHelper";
import { pruneData } from "../utils/filters.js";

export const search = term => dispatch => {
    dispatch({
        type: actionTypes.SET_LOADING,
    });

    getData(`/api/estates/${term}`).then( results => {
        dispatch({
            type: actionTypes.SEARCH,
            payload: {
                results: results
            }
        });
    });
};

export const addFilterValue = (filter, value) => dispatch => {
    dispatch({
        type: actionTypes.ADD_FILTER_VALUE,
        payload: {
            filter: filter,
            value: value
        }
    });
};

export const setSorting = sorting => dispatch => {
    dispatch({
        type: actionTypes.SET_SORTING,
        payload: {
            sorting: sorting
        }
    })
}

export const removeFilterValue = (filter, value) => dispatch => {
    dispatch({
        type: actionTypes.REMOVE_FILTER_VALUE,
        payload: {
            filter: filter,
            value: value
        }
    });
};

export const requestData = () => dispatch => {
    Promise.all([
        getData('/api/estate-categories'),
        getData('/api/estate-price-classes'),
        getData('/api/estate-cities'),
        getData('/api/estates')
    ]).then( results => {
        dispatch({
            type: actionTypes.INITIALIZE_DATA,
            payload: pruneData(
                results[0],
                results[1],
                results[2],
                results[3]
            )
        });
    });
}
