import React from 'react';

const GoogleMapOfficeMarker = ({ address }) => (
    <a
        className="google-map-office-marker"
        href={`https://www.google.com/maps/search/${address}`}
        target="_blank"
        rel="noopener noreferrer"
    >
        <img src={window.mapMarker} alt={address} />
    </a>
);

export { GoogleMapOfficeMarker };
