import React from 'react';
import { Provider } from "react-redux";

import { EstateSearch } from "/components/estate-search/components";
import createStore from "../../../store/createStore";

const EstateSearchApp = () => {
    return (
        <Provider store={ createStore() } >
            <EstateSearch />
        </Provider>
    );
};

export { EstateSearchApp };