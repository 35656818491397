import React from 'react';
import ReactDOM from 'react-dom';

import { GoogleMap } from '/components/google-map/components';

(() => {
    const googleMaps = document.querySelectorAll('[data-google-map]');

    googleMaps.forEach((googleMap) => {
        const {
            address,
            latitude,
            longitude,
        } = googleMap.dataset;

        ReactDOM.render(
            <GoogleMap
                address={address}
                latitude={+latitude}
                longitude={+longitude}
            />,
            googleMap,
        );
    });
})();
