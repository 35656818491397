import React from "react";

const EstateSearchRefinementListItem = props => {
    return (
        <li className="ais-RefinementList-item" >
            <label className="ais-RefinementList-label">
                <input
                    className="ais-RefinementList-checkbox"
                    type="checkbox" defaultChecked={ props.checked }
                    onChange={ () => props.clickHandler(props.attribute, props.option.id) }
                    id={ props.option.id }
                />
                <span className="ais-RefinementList-labelText">{ props.option.title }</span>
            </label>
        </li>
    );
};

export { EstateSearchRefinementListItem };