import { useState } from 'react';

import { useOnClickOutside } from "/hooks";

const useDropdown = (ref, initialState = 'closed') => {
    const [status, setStatus] = useState(initialState);

    const open = () => {
        setStatus('open');
    };

    const close = () => {
        setStatus('closed');
    };

    const toggle = () => {
        if (status === 'closed') {
            open();
        } else {
            close();
        }
    };

    useOnClickOutside(ref, close);

    return {
        status,
        open,
        close,
        toggle,
    };
};

export { useDropdown };
