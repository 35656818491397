import React from 'react';
import ReactDOM from 'react-dom';

import { EstateSearchApp } from '/components/estate-search/components';

(() => {
    const estateSearch = document.querySelector('[data-estate-search]');

    if (estateSearch) {
        ReactDOM.render(
            <EstateSearchApp />,
            estateSearch
        );
    }
})();
