import React from "react";
import { connect } from "react-redux";
import * as actions from "../../../store/actions";
import { LocalStorageService } from "/services";

import { EstateTeaser } from "/components/estate-teaser/EstateTeaser";
import { EstateNavigationService } from "/services";
import Select from "react-select";

const sortByPriceAscending = items => items.sort((a, b) => {
    if (b.displayPrice === false) {
        return -1;
    }

    return parseInt(a.price) - parseInt(b.price);
});

const sortByPriceDescending = items => items.sort((a, b) => {
    if (b.displayPrice === false) {
        return -1;
    }

    return parseInt(b.price) - parseInt(a.price);
});

const sortByDateAscending = items => items.sort((a, b) => {
    return Date.parse(a.postDate.date) - Date.parse(b.postDate.date);
});

const sortByDateDescending = items => items.sort((a, b) => {
    return Date.parse(b.postDate.date) - Date.parse(a.postDate.date);
});

const sortings = {
    PRICE_ASCENDING: {
        key: "PRICE_ASCENDING",
        label: "Prijs goedkoopste eerst",
        sort: sortByPriceAscending,
    },
    PRICE_DESCENDING: {
        key: "PRICE_DESCENDING",
        label: "Prijs duurste eerst",
        sort: sortByPriceDescending,
    },
    DATE_ASCENDING: {
        key: "DATE_ASCENDING",
        label: "Oudste eerst",
        sort: sortByDateAscending,
    },
    DATE_DESCENDING: {
        key: "DATE_DESCENDING",
        label: "Nieuwste eerst",
        sort: sortByDateDescending,
    },
};

const InternalEstateSearchResultsList = props => {
    const { view, setView } = props;

    const sort = items => sortings[props.sorting].sort(items);

    const sortedEstates = sort(props.hits);

    const internalSetSorting = sorting => {
        props.setSorting(sorting);
        LocalStorageService.set("estate-search-sorting", sorting);
    };

    EstateNavigationService.setSearchResults(sortedEstates.map( estate => estate.url));

    const getOverviewTopClassName = () => {
        if (view === 'list') {
            return 'is-list Overview-top';
        } else if (view === 'map') {
            return 'is-map Overview-top';
        } else {
            return 'Overview-top';
        }
    };

    const getCapitalizedView = x => {
        if (x === 'view') {
            return 'View';
        } else if (x === 'both') {
            return 'Both';
        } else if (x === 'list') {
            return 'List';
        } else {
            return '';
        }
    };

    return (
        <>
        <div id="js-overviewTop" className={ getOverviewTopClassName() }>
            <div className={`Overview-topLeft ${ view !== 'list' ? 'is-wide' : '' }`} >
                { view === 'both' && (
                    <button className="Overview-mapButton Overview-mapButton--show" onClick={() => { view = 'map'; closeScroll(); scrollToMap(); } }>{ window.onlyMap }</button>
                )}
                { view === 'list' && (
                    <button className="Overview-mapButton Overview-mapButton--show" onClick={() => { view = 'both'; openMap(); } }>{ window.showMap }</button>
                )}
                { view === 'map' && (
                    <button className="Overview-mapButton Overview-mapButton--close" onClick={() => { view = 'both'; openMap(); } }>{ window.closeMap }</button>
                )}
                { view === 'both' && (
                    <button className="Overview-mapButton Overview-mapButton--close" onClick={() => { view = 'list'; closeScroll(); } }>{ window.closeMapString }</button>
                )}
            </div> 

            { view === 'list' && (
                <div className="u-flex u-flexJustifyCenter">
                    <div className="Overview-topTitle">VERVANG DEZE TEKST</div>
                </div> 
            )}
            <div className={`Overview-topRight ${ view === 'list' ? 'u-flexShrink1' : '' }`} >
                { view === 'list' && (
                    <div className="u-flexGrow1"></div>
                )}
                { view !== 'list' && (
                    <div className="Overview-topTitle">VERVANG DEZE TEKST</div>
                )}
                { view !== 'map' && (
                    <div className="Filter-sorting u-flex u-flexAlignItemsBaseline" >
                        <div className="u-marginRsm"><small>Sorteren op:</small></div>
                        <Select
                            className="react-select"
                            classNamePrefix="react-select"
                            options={Object.values(sortings)}
                            getOptionLabel={option => option.label}
                            getOptionValue={option => option.key}
                            value={sortings[props.sorting]}
                            isSearchable={false}
                            onChange={(option) => { internalSetSorting(option.key); }}
                            placeholder="Sorteer op"
                        />
                    </div>
                )}
                </div>
            { view === 'map' && (
                <div></div>
            )}
        </div>

        {/* <div className="Overview" className={`${loading ? 'is-loading' : ''} Overview--show${getCapitalizedView()}`} > */}
        <div className={`Overview Overview--show${getCapitalizedView(props.view)}`} >
            {/*
                { loading && (
                    <div className="Overview-spinnerWrapper">
                        <div className="Overview-spinner">
                            <div className="rect1"></div>
                            <div className="rect2"></div>
                            <div className="rect3"></div>
                            <div className="rect4"></div>
                            <div className="rect5"></div>
                        </div>
                    </div>
                )}
            */}

            { (view === 'both' || view === 'map') && (
                <div className="Overview-map" id="js-overviewMap"></div>
            )}

            { (view === 'both' || view === 'list') && (
                <div className="Overview-list js-overviewList">
                    <div className="Overview-listWrapper">
                        {sortedEstates.map((estate, index) => (
                            <React.Fragment key={`${view}-${estate.id}`}>
                                <EstateTeaser estate={estate} />
                            </React.Fragment>
                        ))}
                        <div className="Overview-noResults" v-if="estates.length == 0 && ! loading">
                            <div>Er werden geen panden gevonden voor deze zoekopdracht</div>
                        </div>
                        {/*
                            <div className="Overview-cta" v-if="form">
                                <h2 className="Overview-ctaTitle">{{ formtitle }}</h2>
                                <p className="Overview-ctaIntro" v-html="formintro"></p>
                                <span v-html="JSON.parse(form)"></span>
                            </div>
                        */}
                    </div>
                </div>
            )}
        </div>
        </>
    );
};

const mapStateToProps = state => {
    return {
        allEstates: state.categories.allEstates,
        sorting: state.filterValues.sorting
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setSorting: sorting => dispatch(actions.setSorting(sorting))
    }
};

const EstateSearchResultsList = connect(mapStateToProps, mapDispatchToProps)(InternalEstateSearchResultsList)

export { EstateSearchResultsList };
