import React, { useState } from 'react';
import { connect } from "react-redux";
import ReactDOM from 'react-dom';

import {
    EstateSearchResultsList,
    EstateSearchResultsMap,
} from '/components/estate-search/components';

import { LocalStorageService } from '/services';

const InternalEstateSearchResults = props => {
    const estateSearchResultsContainer = document.querySelector(
        '[data-estate-search-results]',
    );

    if (!Boolean(estateSearchResultsContainer)) { return null; }

    let initialView = 'list';
    if (window.allowMapView && window.innerWidth > 959) {
        initialView = LocalStorageService.get('view') || 'list';
    }

    const [view, internalSetView] = useState(initialView);

    const setView = (value) => {
        LocalStorageService.set('view', value);
        internalSetView(value);
    };

    let selectedEstates = props.allEstates;

    if (props.filterValues.categories.length > 0)
        selectedEstates = selectedEstates.filter( estate => props.filterValues.categories.includes(estate.category));
    
    if (props.filterValues.purposes.length > 0)
        selectedEstates = selectedEstates.filter( estate => props.filterValues.purposes.includes(estate.purpose));

    if (props.filterValues.priceClasses.length > 0)
        selectedEstates = selectedEstates.filter( estate => props.filterValues.priceClasses.includes(estate.priceClass));

    if (props.filterValues.cities.length > 0)
        selectedEstates = selectedEstates.filter( estate => props.filterValues.cities.includes(estate.city));

    if (props.filterValues.search)
        selectedEstates = selectedEstates.filter( estate => props.filterValues.searchResults.includes(estate.id));

    return ReactDOM.createPortal(
        <>
            <EstateSearchResultsList
                hits={selectedEstates}
                view={view}
                setView={setView}
            />
        </>,
        estateSearchResultsContainer,
    );
};

const mapStateToProps = state => {
    return {
        allEstates: state.categories.allEstates,
        filterValues: state.filterValues
    }
};

const EstateSearchResults = connect(mapStateToProps, null)(InternalEstateSearchResults);

export { EstateSearchResults };
