import React from 'react';
import PropTypes from 'prop-types';

import Flickity from 'react-flickity-component';
import 'flickity/dist/flickity.css';

import { PriceFormattingService } from '/services';

const EstateTeaserLink = ({ estate, children }) => {
    if (estate.isAvailable) {
        return (
            <a className="estate-teaser__link" href={estate.url}>{children}</a>
        );
    }

    return children;
};

class EstateTeaser extends React.Component {

    isAvailable = availability => availability === 'te koop' || availability === 'te huur';

    render = () => {
        const { estate } = this.props;

        const onMouseEnter = () => {
            if (window.setActiveHitId) {
                window.setActiveHitId(estate.id);
            }
        };

        return (
            <div data-estate-id={ estate.id } className={`Estate js-estate ${ estate.custom ? 'Estate-custom' : '' }`} onMouseEnter={ onMouseEnter } >

                <img class="Estate-image" src="estate.image"/>

                <div class="Estate-leftlabels">
                    <div class="Estate-typeLabel">{ estate.purposeName }</div>
                    { estate.isNew && (
                        <div class="Estate-newLabel">Nieuw</div>
                    )}
                </div>

                <div class="Estate-labels" v-if="estate.projectResale || estate.projectInvestment || estate.projectNewConstruction">
                    <div class="Estate-label" v-if="activeCat != 'verhuur' && estate.projectInvestment">Investering</div>
                    <div class="Estate-label" v-if="activeCat != 'verhuur' && estate.projectNewConstruction">Nieuwbouw</div>
                </div>

                <div class="Estate-bar">
                    <div class="Estate-icons u-paddingTxs u-paddingBxs">
                        <div class="Estate-icon Estate-icon--bed" v-if="estate.projectBedrooms">
                            <img src="/assets/img/data-bedroom-dark.png"/>{ estate.bedrooms }
                        </div>
                        <div class="Estate-icon Estate-icon--bath" v-if="estate.projectBathrooms">
                            <img src="/assets/img/data-bathroom-dark.png"/>{ estate.bathrooms }
                        </div>
                        <div class="Estate-icon Estate-icon--garage" v-if="estate.projectParkings">
                            <img src="/assets/img/data-garage-dark.png"/>{ estate.garages }
                        </div>
                        <div class="Estate-icon Estate-icon--surface" v-if="estate.groundArea && parseInt(estate.groundArea) > 0">
                            <img src="/assets/img/data-surface-dark.png"/>{ estate.groundArea }m<sup>2</sup>
                        </div>
                        <div class="Estate-icon Estate-icon--surface" v-if="estate.area && parseInt(estate.area) > 0">
                            <img src="/assets/img/data-surface-living.png"/>{ estate.area }m<sup>2</sup>
                        </div>
                    </div>

                    { estate.isProject && (
                        <div class="Estate-price">
                            <span>Vanaf&nbsp;</span>&euro;{ estate.price }
                        </div>
                    )}

                </div>

                <div class="Estate-info">
                    <div class="Estate-bottom">
                        { estate.subcategory }&nbsp;-&nbsp;<strong>{ estate.displayCity }</strong></div>
                    <div class="Estate-link">
                        <a href="estate.url" class="Button Button--secondary Button--small">
                            Meer details
                        </a>
                    </div>
                </div>

                {/*
                    <template v-if="estate.custom">
                        <h2 class="Estate-customTitle">{{ estate.title }}</h2>
                        <div class="Estate-customText">{{ estate.text }}</div>
                        <a v-if="estate.btn && estate.url" :href="estate.url" :target="estate.target" class="Button Button--primary Estate-customButton">{{ estate.btn }}</a>
                    </template>
                */}

            </div>
        );
    };
}

EstateTeaser.propTypes = {
    estate: PropTypes.shape({
        area: PropTypes.number,
        bedrooms: PropTypes.number,
        bathrooms: PropTypes.number,
        displayCity: PropTypes.string,
        displayPrice: PropTypes.bool,
        id: PropTypes.string,
        image: PropTypes.string,
        isProject: PropTypes.bool,
        price: PropTypes.number,
        purpose: PropTypes.string,
        url: PropTypes.string.isRequired,
        subcategory: PropTypes.string,
        surface: PropTypes.number,
        title: PropTypes.string.isRequired,
    }).isRequired,
};

export { EstateTeaser };
