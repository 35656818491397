import React from 'react';
import ReactDOM from 'react-dom';

import { EstateNavigation } from '/components/estate-navigation/components';

(() => {
    const estateNavigation = document.querySelector('[data-estate-navigation]');

    if (estateNavigation) {
        ReactDOM.render(
            <EstateNavigation />,
            estateNavigation,
        );
    }
})();
