import React from 'react';
import { connect } from "react-redux";

const InternalEstateSearchQuicklinks = props => {
    const isSelected = id => props.selectedPurposes.includes(id);

    return (
        <>
        {props.purposes.map( purpose => (
            <button
                className={`button is-orange font-supplementary--bold ${isSelected(purpose.id) && "is-active"}`}
                onClick={ () => props.clickHandler("purposes", purpose.id) }
                type="button"
                key={purpose.id}
            >
                {purpose.name}
            </button>
        ))}
        </>
    );
};

const mapStateToProps = state => {
    return {
        purposes: state.categories.purposes,
        selectedPurposes: state.filterValues.purposes
    };
}

const EstateSearchQuicklinks = connect(mapStateToProps, null)(InternalEstateSearchQuicklinks);

export { EstateSearchQuicklinks };
