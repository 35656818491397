import React from 'react';
import ReactDOM from 'react-dom';

import { EstateTeaser } from '/components/estate-teaser/EstateTeaser';

(() => {
    const estateTeasers = document.querySelectorAll('[data-estate-teaser]');

    estateTeasers.forEach((estateTeaser) => {
        const estate = {
            ...estateTeaser.dataset,
            images: JSON.parse(estateTeaser.dataset.images),
        };

        ReactDOM.render(
            <EstateTeaser estate={estate} />,
            estateTeaser,
        );
    });
})();
