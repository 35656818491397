import * as actionTypes from "../actionTypes";

const initialState = {
    loaded: false,

    allEstates: [],
    categories: [],
    cities: [],
    priceClasses: [],
    bedrooms: [],
    transactions: [
        {
            title: 'Te huur',
            id: 'te-huur'
        },
        {
            title: 'Te koop',
            id: 'te-koop'
        }
    ]
};

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INITIALIZE_DATA:
            return {
                ...state,

                loaded: true,

                categories: action.payload.categories,
                cities: action.payload.cities,
                priceClasses: action.payload.priceClasses,
                allEstates: action.payload.allEstates,
                bedrooms: action.payload.bedrooms
            }
        default:
            return state;
    }
};
