import React from 'react';
import history from '../../../utils/history';
import { connect } from 'react-redux';
import * as actions from "../../../store/actions";
import { toQueryString } from "../../../utils/uriHelper";

import {
    EstateSearchOverlay,
    EstateSearchFilter,
    EstateSearchResults,
} from '/components/estate-search/components';

class InternalEstateSearch extends React.Component {

    state = {
        showMore:  window.innerWidth > 599
    };

    setFilterValue = async (name, value) => {
        let filterValues = {
            categories: [...this.props.filterValues.categories],
            cities: [...this.props.filterValues.cities],
            priceClasses: [...this.props.filterValues.priceClasses],
            transactions: this.props.filterValues.transactions
        }

        if (!this.props.filterValues[name].includes(value))
            filterValues[name] = filterValues[name].concat(value);

        else
            filterValues[name] = filterValues[name].filter( item => `${item}` !== `${value}` );
        
        this.redirect(filterValues);
    };

    toggleCheckbox = name => {
    }

    redirect = filterValues => {
        history.push(`${history.location.pathname}${toQueryString(filterValues)}`);
    };

    updateTransaction = e => {
        e.preventDefault();
        this.setState({transactions: e.target.value});
    };

    componentDidMount = () => {
        this.props.requestData();
    };

    clearFilter = () => {
        history.push(history.location.pathname);
    };

    render = () => {
        if (!this.props.loaded) {
            return <h2 style={{ textAlign: "center" }}>{ window.loadingEstates }</h2>
        } else {
            return (
                <>
                <div>
                    <div className="u-flex u-xs-flexCol u-flexAlignItemsBaseline u-flexJustifyBetween">
                        <div className="Grid Grid--withGutter FiltersGrid">
                            <div className="Grid-cell u-xs-sizeFull u-sizeFit">
                                <select onChange={(e) => updateTransaction(e)}  name="transactions" id="transactions">
                                    <option value="12770">Te koop</option>
                                    <option value="12771">Te huur</option>
                                </select>
                            </div>
                            <div className="Grid-cell u-xs-sizeFull u-sizeFit">
                                <EstateSearchFilter
                                    label={ window.categories }
                                    attribute="categories"
                                    clickHandler={this.setFilterValue}
                                    className="multiselect has-multiple"
                                />
                            </div>
                            <div className="Grid-cell u-xs-sizeFull u-sizeFit">
                                <EstateSearchFilter
                                    label={ window.cities }
                                    attribute="cities"
                                    clickHandler={this.setFilterValue}
                                    className="multiselect has-multiple"
                                />
                            </div>
                            <div className="Grid-cell u-xs-sizeFull u-sizeFit">
                                <EstateSearchFilter
                                    label={ window.priceClasses }
                                    attribute="priceClasses"
                                    clickHandler={this.setFilterValue}
                                    className="multiselect has-multiple"
                                />
                            </div>
                        </div>
                        <div className="Filter-toggleMore u-flex u-flexCol u-xs-flexAlignItemsCenter u-flexAlignItemsEnd u-xs-sizeFull">

                            { !this.state.showMore && (
                                <a href="#" onClick={ () => this.setState({ showMore: true }) } >{ window.moreOptions }&nbsp;<img src="/assets/img/icons/filters-plus.png" /></a>
                            )}

                            { this.state.showMore && (
                                <a href="#" onClick={ () => this.setState({ showMore: false }) } >{ window.lessOptions }&nbsp;<img src="/assets/img/icons/filters-minus.png" /></a>
                            )}

                            <a href="#" className="Filter-toggleReset" onClick={ this.clearFilter } ><small>{ window.clearFilters }</small></a>

                        </div>
                    </div>

                    { this.state.showMore && (
                        <div className="u-flex u-xs-flexCol u-xs-flexAlignItemsCenter u-flexAlignItemsBaseline u-flexJustifyBetween u-marginTmd">
                            <div className="Grid Grid--withGutter FiltersGrid">
                                <div className="Grid-cell u-xs-sizeFull u-sizeFit">
                                    <EstateSearchFilter
                                        label={ window.bedrooms }
                                        attribute="bedrooms"
                                        clickHandler={this.setFilterValue}
                                        className="multiselect has-multiple"
                                    />
                                </div>
                                <div className="Grid-cell u-xs-marginTmd u-xs-sizeFull u-sizeFit u-flex u-xs-flexCol u-flexAlignItemsBaseline Filters-checkboxes">
                                    <strong className="">{ window.showOnly }:</strong>
                                    <label className="Form-field Form-field--checkbox">
                                        <input type="checkbox" name="newconstruction" value="1" onChange={ () => this.toggleCheckbox("newConstruction") } />
                                        <span className="Form-indicator"></span>{ window.newConstruction }
                                    </label>
                                    <label className="Form-field Form-field--checkbox">
                                        <input type="checkbox" name="investment" value="1" onChange={ () => this.toggleCheckbox("investment") } />
                                        <span className="Form-indicator"></span>{ window.investment }
                                    </label>
                                    <label className="Form-field Form-field--checkbox">
                                        <input type="checkbox" name="resale" value="1" onChange={ () => this.toggleCheckbox("resale") } />
                                        <span className="Form-indicator"></span>{ window.resale }
                                    </label>
                                </div>
                            </div>
                        </div>
                    )}

                </div>
                <EstateSearchResults />
                </>
            );
        }
    };
}

const mapStateToProps = state => {
    return {
        loaded: state.categories.loaded,
        filterValues: state.filterValues,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        requestData: () => dispatch(actions.requestData()),
    };
}

const EstateSearch = connect(mapStateToProps, mapDispatchToProps)(InternalEstateSearch);

export { EstateSearch };
