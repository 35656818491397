import React from 'react';
import { connect } from 'react-redux';

import { EstateSearchRefinementListItem } from "./EstateSearchRefinementListItem";

const InternalEstateSearchRefinementList = props => {

    const uuidv4 = () => {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    const isChecked = id => props.filterValues[props.attribute].includes(id);

    const populateList = () => props.options
        .filter( option => option.count > 0)
        .map( option => <EstateSearchRefinementListItem
            attribute={ props.attribute }
            clickHandler={ props.clickHandler }
            key={ uuidv4() }
            option={ option }
            checked={ isChecked(option.id)}
            />
        );

    return (
        <div className="ais-RefinementList">
            <ul className="ais-RefinementList-list">
                { populateList() }
            </ul>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        filterValues: state.filterValues
    };
}

const EstateSearchRefinementList = connect(mapStateToProps, null)(InternalEstateSearchRefinementList);

export { EstateSearchRefinementList };
