import React, { useState, useEffect } from 'react';

import GoogleMap from 'google-map-react';
import { fitBounds } from 'google-map-react/utils';

import { EstateTeaser } from '/components/estate-teaser/EstateTeaser';

const getCenterAndZoom = (hits) => {
    const xxx = hits.filter(hit => hit.latitude && hit.longitude);

    if (xxx.length === 0) {
        return;
    }

    if (xxx.length === 1) {
        const first = hits[0];

        return {
            center: {
                lat: +first.latitude,
                lng: +first.longitude,
            },
            zoom: 12,
        };
    }

    const latitudes = [];
    xxx.forEach((hit) => {
        if (latitudes.includes(+hit.latitude) === false) {
            latitudes.push(+hit.latitude);
        }
    });
    const longitudes = [];
    xxx.forEach((hit) => {
        if (longitudes.includes(+hit.longitude) === false) {
            longitudes.push(+hit.longitude);
        }
    });

    if (latitudes.length === 1 && longitudes.length === 1) {
        return {
            center: {
                lat: latitudes[0],
                lng: longitudes[0],
            },
            zoom: 12,
        }
    }

    const mapBCR = document.querySelector('.estate-search-results-map').getBoundingClientRect();

    const bounds = {
        nw: {
            lat: Math.max(...latitudes),
            lng: Math.min(...longitudes),
        },
        se: {
            lat: Math.min(...latitudes),
            lng: Math.max(...longitudes),
        },
    };

    const size = {
        width: mapBCR.width,
        height: mapBCR.height,
    };

    return fitBounds(bounds, size);
};

const MapTeaser = ({ estate, active, onClick }) => {
    const classNames = ['estate-search-results-map-result'];

    if (active) {
        classNames.push('is-active');
    }

    return (
        <div className={classNames.join(' ')}>
            {active && (
                <div className="estate-search-results-map-result__teaser">
                    <button className="estate-search-results-map-result__close" onClick={onClick}>
                        <div className="close-icon">
                            <span className="close-icon__bar" />
                            <span className="close-icon__bar" />
                        </div>
                    </button>
                    <EstateTeaser estate={estate} />
                </div>
            )}
            <button className="estate-search-results-map-result__marker" type="button" onClick={onClick}>
                <svg viewBox="0 0 22 27">
                    <path d="M11 0A11 11 0 000 11c0 7.23 7.15 10.7 8.05 11.5.91.83 1.67 2.67 1.97 3.72.15.5.57.76.98.77.41 0 .83-.27.98-.77.3-1.05 1.06-2.9 1.97-3.72.9-.8 8.05-4.27 8.05-11.5A11 11 0 0011 0z" />
                    <path fill="#FFF" d="M11 14a3 3 0 110-6 3 3 0 010 6z" />
                </svg>
            </button>
        </div>
    );
};

const EstateSearchResultsMap = ({ hits }) => {
    const [center, setCenter] = useState(null);
    const [zoom, setZoom] = useState(12);

    const [activeHit, setActiveHit] = useState(null);

    window.setActiveHitId = (id) => {
        setActiveHit({
            id,
        });
    };

    const handleMarkerClick = (hit) => {
        if (activeHit && activeHit.id === hit.id) {
            setActiveHit(null);
            return;
        }

        setActiveHit(hit);
    };

    useEffect(() => {
        const xxx = getCenterAndZoom(hits);

        if (xxx) {
            setCenter(xxx.center);
            setZoom(xxx.zoom);
        }
    }, [hits]);

    return (
        <div className="estate-search-results-map">
            <GoogleMap
                bootstrapURLKeys={{ key: process.env.GOOGLE_MAPS_API_KEY }}
                center={center}
                zoom={zoom}
                key="google-map"
            >
                {hits.filter(hit => hit.latitude && hit.longitude).map((hit) => (
                    <MapTeaser
                        active={activeHit && activeHit.id === hit.id}
                        estate={hit}
                        key={hit.id}
                        lat={+hit.latitude}
                        lng={+hit.longitude}
                        onClick={() => { handleMarkerClick(hit); }}
                    />
                ))}
            </GoogleMap>
        </div>
    );
};

export { EstateSearchResultsMap };
