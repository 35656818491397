import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { useDropdown } from '/hooks';
import { Dropdown } from '/svg/dropdown';

import { EstateSearchRefinementList } from "./EstateSearchRefinementList";

const transform = (items) => items.sort((a, b) => {
    if (a.label.includes('meer ') || a.label.includes('>')) {
        return 9999;
    }

    if (a.label.includes('minder ') || a.label.includes('<')) {
        return -9999;
    }

    if (a.label < b.label) {
        return -1;
    }

    if (a.label > b.label) {
        return 1;
    }

    return 0;
});

const InternalEstateSearchFilter = props => {
    const { label, singular, plural, attribute } = props;
    const component = useRef(null);
    const { status, toggle } = useDropdown(component, 'closed');

    return (
        <div className={`estate-search-filter is-${status}`} ref={component}>
            <button className="estate-search-filter__trigger" onClick={toggle} >
                {label}
            </button>
            <div className="estate-search-filter__icon">
                <Dropdown />
            </div>
            <div className="estate-search-filter__content">
                <EstateSearchRefinementList attribute={ props.attribute } clickHandler={ props.clickHandler } options={ props.categories[attribute] } />
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        categories: state.categories
    }
};

const EstateSearchFilter = connect(mapStateToProps, null)(InternalEstateSearchFilter);

export { EstateSearchFilter };
